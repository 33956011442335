.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


body {
  font-family: Prompt;
  font-size: 16px;
  position: relative;
  padding-bottom: 58px;
  min-height: 100vh;


}

main {
  width: 95vw;
  margin: 0 auto;
  padding: 30px 15px;
  min-height: calc(100vh - 211px - 58px);
  /* color: #ffffff; */
  background-color: #ECF0F1;
}

footer {
  text-align: center;
  background-color: #717171;
  color: #f2f2f2;
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.prompt-regular {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.prompt-semibold {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.prompt-bold {
  font-family: "Prompt", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.fa {
  padding: 10px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

/* Set a specific color for each brand */
/* Facebook */
.fa-facebook {
  background: #3B5998;
  color: white;
}

/* Twitter */
.fa-twitter {
  background: #55ACEE;
  color: white;
}

* {
  box-sizing: border-box
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: .4
  }

  to {
    opacity: 1
  }
}

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}

.carousel-item>img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}

* {
  box-sizing: border-box;
}



/* Header/Blog Title */
.header {
  padding: 30px;
  font-size: 40px;
  text-align: center;
  background: white;
}

/* Create two unequal columns that floats next to each other */
/* Left column */
.leftcolumn {
  float: left;
  width: 75%;
}

/* Right column */
.rightcolumn {
  float: left;
  width: 25%;
  padding-left: 10px;
}

/* Fake image */
.fakeimg {
  background-color: #aaa;
  width: 100%;
  padding: 20px;
}

/* Add a card effect for articles */
.card {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}



/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {

  .leftcolumn,
  .rightcolumn {
    width: 100%;
    padding: 0;
  }
}



.carousel-item {
  height: 32rem;
  background: #777;
  color: white;

}

/* paginate css */
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.responsive-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.navbar .navbar-collapse {
  text-align: center;
}

.carousel-transparent {
  background-color: rgba(255, 255, 255, 0.5);
}


.carousel-indicators li {
  background-color: #49494a;
}

.carousel-indicators .active {
  background-color: #242424;
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
  display: block;
  height: auto;
  max-width: 100%;
  line-height: 1;
  margin: auto;
  width: 100%;
}





.blog-post {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.blog-post .blog-img .overlay,
.blog-post .blog-img .post-meta {
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.blog-post .blog-img .overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blog-post .blog-img .post-meta {
  bottom: 5%;
  right: 5%;
  z-index: 1;
}

.blog-post .blog-img .post-meta .read-more:hover {
  color: #6dc77a !important;
}

.blog-post .content h1,
.blog-post .content h2,
.blog-post .content h3,
.blog-post .content h4,
.blog-post .content h5,
.blog-post .content h6 {
  line-height: 1.2;
}

.blog-post .content .title {
  font-size: 18px;
}

.blog-post .content .title:hover {
  color: #6dc77a !important;
}

.blog-post .content .author .name:hover {
  color: #6dc77a !important;
}

.blog-post:hover {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.blog-post:hover .blog-img .overlay {
  opacity: 0.65;
}

.blog-post:hover .blog-img .post-meta {
  opacity: 1;
}

.blog-post .post-meta .like i,
.profile-post .like i {
  -webkit-text-stroke: 2px #dd2427;
  -webkit-text-fill-color: transparent;
}

.blog-post .post-meta .like:active i,
.blog-post .post-meta .like:focus i,
.profile-post .like:active i,
.profile-post .like:focus i {
  -webkit-text-stroke: 0px #dd2427;
  -webkit-text-fill-color: #dd2427;
}

.avatar.avatar-ex-sm {
  height: 36px;
}

.shadow {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15) !important;
}

.text-muted {
  color: #8492a6 !important;
}


.para-desc {
  max-width: 600px;
}

.text-muted {
  color: #8492a6 !important;
}

.section-title .title {
  letter-spacing: 0.5px;
  font-size: 30px;
}

img {
  max-width: 100%;
  height: auto;
}

.img-fixed-size {
  width: 350px;
  height: auto;

}

/* CSS media query สำหรับหน้าจอที่มีความกว้างน้อยกว่า 992px */
@media (max-width: 992px) {
  .img-fixed-size {
    width: 200px;
  }
}

.navbar-right {
  position: absolute;
  right: 0;
}

.relative {
  position: relative;
}

@media only screen and (max-width:768px) {
  .navbar-brand {
    max-width: 100px;
  }

  /* below is for the demo but might help you position 
  the hamburger menu on mobile */
  .navbar-toggler {
    right: 0;
    position: absolute;
    margin: 10px;
  }
}

/* react-image-gallery */
@import "~react-image-gallery/styles/css/image-gallery.css";

.carousel-item {
  height: 350px;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.carousel-indicators {
  bottom: -50px;
}


.img-link:hover {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.img-link:hover .img-link .overlay {
  opacity: 0.65;
}

.img-link:hover .img-link .img-link-meta {
  opacity: 1;
}
